export default {
  name: 'calculatorCard',
  components: {},
  data: function data() {
    return {
      dialogFormVisible: false,
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      formLabelWidth: '100px'
    };
  },
  mounted: function mounted() {}
};