import IndustryCard from './industryCard.vue';
export default {
  name: 'WhyMynLabs',
  components: {
    IndustryCard: IndustryCard
  },
  data: function data() {
    return {};
  },
  mounted: function mounted() {}
};