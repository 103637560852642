import * as jLottie from '@lottiefiles/jlottie';
import AOS from 'aos';
import 'aos/dist/aos.css';
import QuoteModule from './quoteModule.vue';
export default {
  name: 'WhyMynLabs',
  props: {
    isMain: {
      default: false,
      type: Boolean
    }
  },
  components: {
    QuoteModule: QuoteModule
  },
  data: function data() {
    return {};
  },
  mounted: function mounted() {
    AOS.init();

    if (this.isMain) {
      this.loadLottie('c');
      this.loadLottie('d');
      this.loadLottie('e');
      this.loadLottie('f');
    }
  },
  methods: {
    loadLottie: function loadLottie(lottieID) {
      jLottie.loadAnimation({
        container: document.getElementById(lottieID),
        loop: true,
        autoplay: true,
        path: "/lotties/".concat(lottieID, ".json")
      });
    }
  }
};