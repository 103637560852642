import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  id: "home",
  class: "panel home"
};
var _hoisted_2 = {
  id: "aboutMyneral",
  class: "panel aboutMyneral"
};
var _hoisted_3 = {
  id: "about",
  class: "panel orange"
};
var _hoisted_4 = {
  id: "about",
  class: "panel industryCTA"
};
var _hoisted_5 = {
  id: "contact",
  class: "panel calculatorKits"
};
var _hoisted_6 = {
  id: "contact",
  class: "panel contact"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_background_video = _resolveComponent("background-video");

  var _component_WhatMyneral = _resolveComponent("WhatMyneral");

  var _component_AboutMyneral = _resolveComponent("AboutMyneral");

  var _component_WhyMynLabs = _resolveComponent("WhyMynLabs");

  var _component_IndustryCTA = _resolveComponent("IndustryCTA");

  var _component_quote_module = _resolveComponent("quote-module");

  var _component_FeatCard = _resolveComponent("FeatCard");

  var _component_ContactBlock = _resolveComponent("ContactBlock");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("section", _hoisted_1, [_createVNode(_component_background_video, {
    video_url: "https://res.cloudinary.com/myn/video/upload/du_40p,q_auto:low/v1623414529/public-site/video_ffobnk",
    isMain: "",
    muted: ""
  })]), _createVNode(_component_WhatMyneral), _createElementVNode("section", _hoisted_2, [_createVNode(_component_AboutMyneral)]), _createElementVNode("section", _hoisted_3, [_createVNode(_component_WhyMynLabs, {
    isMain: ""
  })]), _createElementVNode("section", _hoisted_4, [_createVNode(_component_IndustryCTA), _createVNode(_component_quote_module, {
    class: "quote",
    quote: "Your fully integrated blockchain management system guarantees the confidence your clients and customers want - ready to go in hours instead of months."
  })]), _createElementVNode("section", _hoisted_5, [_createVNode(_component_FeatCard)]), _createElementVNode("section", _hoisted_6, [_createVNode(_component_ContactBlock)])], 64);
}