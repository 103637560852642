import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
export default {
  name: 'Sidebar',
  data: function data() {
    return {
      pictureStatic: require('@/assets/1.png'),
      pictureExpanded: require('@/assets/transparent.png'),
      hover: false
    };
  },
  computed: {
    pictureHover: function pictureHover() {
      if (this.hover) {
        return this.pictureExpanded;
      }

      return this.pictureStatic;
    }
  },
  mounted: function mounted() {
    gsap.registerPlugin(ScrollTrigger);
    gsap.from('.image-main', {
      duration: 2,
      scale: 0.5,
      opacity: 0,
      delay: 0.5,
      stagger: 0.2,
      ease: 'elastic'
    });
    var hamburger = document.querySelector('.hamburger');
    var navMenu = document.querySelector('.main-menu');
    var navLinkMining = document.querySelector('.closeMining');
    var navLinkRetail = document.querySelector('.closeRetail'); // const navLinkLogistics = document.querySelector('.closeLogistics');

    var navLinkOil = document.querySelector('.closeOil'); // const navLinkConst = document.querySelector('.closeConstruction');

    var navLinkMan = document.querySelector('.closeManufacturing');
    var navLinkHome = document.querySelector('.closeHome');
    var navLinkContact = document.querySelector('.closeContact');
    var navLinkSolution = document.querySelector('.closeSolution');

    function mobileMenu() {
      hamburger.classList.toggle('active');
      navMenu.classList.toggle('active');
    }

    function closeMenu() {
      hamburger.classList.toggle('active');
      navMenu.classList.toggle('active');
    }

    hamburger.addEventListener('click', mobileMenu);
    navLinkMining.addEventListener('click', closeMenu);
    navLinkRetail.addEventListener('click', closeMenu); // navLinkLogistics.addEventListener('click', closeMenu);

    navLinkOil.addEventListener('click', closeMenu); // navLinkConst.addEventListener('click', closeMenu);

    navLinkMan.addEventListener('click', closeMenu);
    navLinkHome.addEventListener('click', closeMenu);
    navLinkContact.addEventListener('click', closeMenu);
    navLinkSolution.addEventListener('click', closeMenu);
  }
};