import { createRouter, createWebHistory } from 'vue-router';
import Home from '../pages/home/Home.vue';

const routes = [

  {
    path: '/',
    name: 'Home',
    component: Home,
  },

  {
    path: '/industries',
    name: 'Industries',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import('../pages/industries/industries.vue'),
    component: () => import('../pages/industries/industries.vue'),
  },
  {
    path: '/industries/retail',
    name: 'Retail',
    component: () => import('../pages/industries/retail/Retail.vue'),
  },
  {
    path: '/industries/retail/manufacturing',
    name: 'Manufacturing',
    component: () => import('../pages/industries/retail/Manufacturing.vue'),
  },
  {
    path: '/industries/mining',
    name: 'Mining',
    component: () => import('../pages/industries/mining/Mining.vue'),
  },
  {
    path: '/industries/agriculture',
    name: 'Agriculture',
    component: () => import('../pages/industries/agriculture/Agriculture.vue'),
  },
  {
    path: '/industries/logistics',
    name: 'Logistics',
    component: () => import('../pages/industries/logistics/Logistics.vue'),
  },
  {
    path: '/industries/smb',
    name: 'SMB',
    component: () => import('../pages/industries/smb/SMB.vue'),
  },
  {
    path: '/industries/construction',
    name: 'Construction',
    component: () => import('../pages/industries/construction/Construction.vue'),
  },
  {
    path: '/industries/oil',
    name: 'Oil',
    component: () => import('../pages/industries/oil/Oil.vue'),
  },
  {
    path: '/solution',
    name: 'Solution',

    component: () => import('../pages/solution/solution.vue'),
  },
  {
    path: '/contact',
    name: 'Contact',

    component: () => import('../pages/contact/contact.vue'),
  },
  {
    path: '/demo',
    name: 'Demo',

    component: () => import('../pages/contact/demo.vue'),
  },
  {
    path: '/mynlink',
    name: 'MynLink',

    component: () => import('../pages/mynlinkPage/mynlink.vue'),
  },
  {
    path: '/terms',
    name: 'Terms',

    component: () => import('../pages/contact/terms.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
});

export default router;
