import { setTimeout } from 'timers'; // import gsap from 'gsap';

import * as jLottie from '@lottiefiles/jlottie';
export default {
  name: 'BackgroundVideo',
  props: {
    video_url: String,
    heroText: String,
    isMain: {
      default: false,
      type: Boolean
    }
  },
  data: function data() {
    return {
      typeValue: ' ',
      typeStatus: false,
      typeArray: ['mining', 'retail', 'oil', 'manufacturing'],
      typingSpeed: 100,
      erasingSpeed: 100,
      newTextDelay: 2000,
      typeArrayIndex: 0,
      charIndex: 0,
      image: require('@/assets/infrastructureoftrust.png')
    };
  },
  created: function created() {
    if (this.isMain) {
      setTimeout(this.typeText, this.newTextDelay + 200);
    }
  },
  mounted: function mounted() {
    // this.loadLottie('b');
    if (this.isMain) {
      this.loadLottie('a'); // this.loadLottie('b');
    }
  },
  methods: {
    loadLottie: function loadLottie(lottieID) {
      jLottie.loadAnimation({
        container: document.getElementById(lottieID),
        loop: true,
        autoplay: true,
        useWebWorker: true,
        path: "/lotties/".concat(lottieID, ".json")
      });
    },
    typeText: function typeText() {
      if (this.charIndex < this.typeArray[this.typeArrayIndex].length) {
        if (!this.typeStatus) this.typeStatus = true;
        this.typeValue += this.typeArray[this.typeArrayIndex].charAt(this.charIndex);
        this.charIndex += 1;
        setTimeout(this.typeText, this.typingSpeed);
      } else {
        this.typeStatus = false;
        setTimeout(this.eraseText, this.newTextDelay);
      }
    },
    eraseText: function eraseText() {
      if (this.charIndex > 0) {
        if (!this.typeStatus) this.typeStatus = true;
        this.typeValue = this.typeArray[this.typeArrayIndex].substring(0, this.charIndex - 1);
        this.charIndex -= 1;
        setTimeout(this.eraseText, this.erasingSpeed);
      } else {
        this.typeStatus = false;
        this.typeArrayIndex += 1;

        if (this.typeArrayIndex >= this.typeArray.length) {
          this.typeArrayIndex = 0;
        }

        setTimeout(this.typeText, this.typingSpeed + 1000);
      }
    }
  }
};