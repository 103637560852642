import makeInstaller from 'element-plus/lib/make-installer';

import {
  ElButton,
  ElMain,
  ElHeader,
  ElFooter,
  ElDialog,
  ElForm,
  ElFormItem,
  ElSelect,
  ElOption,
  ElSwitch,
  ElCheckbox,
  ElRadioGroup,
  ElRadio,
  ElAlert,
  ElCard,
  ElStep,
  ElSteps,
  ElBacktop,
  ElRow,
  ElCol,
  ElContainer,
  ElInput,
  ElMessageBox,
  ElCarousel,
  ElImage,
} from 'element-plus';

const components = [
  ElButton,
  ElMain,
  ElHeader,
  ElFooter,
  ElDialog,
  ElForm,
  ElFormItem,
  ElSelect,
  ElOption,
  ElSwitch,
  ElCheckbox,
  ElRadioGroup,
  ElRadio,
  ElAlert,
  ElCard,
  ElStep,
  ElSteps,
  ElBacktop,
  ElRow,
  ElCol,
  ElContainer,
  ElInput,
  ElMessageBox,
  ElCarousel,
  ElImage,
];

const plugins = [];

export default makeInstaller([...components, ...plugins]);
