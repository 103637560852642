import "core-js/modules/web.dom-collections.for-each.js";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import BackgroundVideo from '../../components/backgroundVideo.vue';
import WhyMynLabs from '../../components/WhyMyn.vue';
import IndustryCTA from '../../components/industryCTA.vue';
import QuoteModule from '../../components/quoteModule.vue';
import WhatMyneral from '../../components/whatMyneral.vue';
import ContactBlock from '../../components/contactBlock.vue';
import FeatCard from '../../components/featComparisonCard.vue';
import AboutMyneral from '../../components/aboutMyneral.vue';
export default {
  components: {
    BackgroundVideo: BackgroundVideo,
    WhyMynLabs: WhyMynLabs,
    IndustryCTA: IndustryCTA,
    QuoteModule: QuoteModule,
    WhatMyneral: WhatMyneral,
    ContactBlock: ContactBlock,
    FeatCard: FeatCard,
    AboutMyneral: AboutMyneral
  },
  data: function data() {
    return {};
  },
  mounted: function mounted() {
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.matchMedia({
      // eslint-disable-next-line
      '(min-width: 800px)': function minWidth800px() {
        gsap.utils.toArray('.panel').forEach(function (panel) {
          ScrollTrigger.create({
            trigger: panel,
            start: 'top top',
            endTrigger: '.footer',
            pin: true,
            pinSpacing: false
          });
        });
      }
    });
  }
};