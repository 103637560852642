export default {
  name: 'QuoteModule',
  props: {
    quote: String
  },
  data: function data() {
    return {
      active: 0
    };
  },
  methods: {}
};